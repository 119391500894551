import React, { Component } from "react";
import { connect } from "react-redux";
// import { completeToDo } from "../actions";
import * as viewActions from "./ViewFile";
import * as actions from "../actions";
// import { deletePDF } from "../actions";
import { PDFListRef } from "../config/firebase";
import * as firebase from "firebase";
import { Router, Link } from "react-router-dom";

class ListItem extends Component {
  //   completeClick = completeTodoId => {
  //     const { completeToDo } = this.props;
  //     completeToDo(completeTodoId);
  //   };

  deleteClick = (deletePDFId, uuid, editVersionStoreID, oringinStoreID) => {
    var keyToDelete = [deletePDFId];

    const { deletePDF } = this.props;
    console.log("delete PDF Id", deletePDFId);
    console.log("deletePDF", deletePDF);
    console.log("key To Delete", keyToDelete);

    // const abc = keyToDelete.map(FirebaseStoreID => {
    //   return PDFListRef.child("videos")
    //     .child(FirebaseStoreID)
    //     .on("value", s => s);
    // });
    // console.log("abc", abc);

    if (editVersionStoreID != null && oringinStoreID != null) {
      var source_images_url = "source_images".concat("/", uuid);
      var sourceStorageRef = firebase.storage().ref(source_images_url);

      var edit_images_url = "edit_images"; //.concat("/", uuid);
      var editStorageRef = firebase.storage().ref(edit_images_url);

      var deleteSource = sourceStorageRef.child(oringinStoreID);
      var deleteEdit = editStorageRef.child(uuid);
      // Delete the file
      deleteSource
        .delete()
        .then(function() {
          // File deleted successfully
          deleteEdit
            .listAll()
            .then((dir) => {
              dir.items.forEach((fileRef) => {
                fileRef.delete();
              });
              deletePDF(deletePDFId);
            })
            .catch(function(error) {
              if (error) {
                // Uh-oh, an error occurred!
                console.log("CANNOT delete edit file!!!");
              } else {
                console.log(
                  `All the Firebase Storage files in users/${uuid}/ have been deleted`
                );
              }
            });
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!
          console.log("CANNOT delete source file!!!");
        });
    }
  };

  render() {
    console.log("render this.props", this.props);
    const { pdfId, pdf, getDisplayData } = this.props;
    console.log("pdfId, pdf, getDisplayData", pdfId, pdf, getDisplayData);

    return (
      // <Router>
      <tr key="" className="bg-gray-300">
        <td className="text-base w-1/4 px-4 py-2">
          {pdf.fileID == null ? "no data" : pdf.fileID}
        </td>
        <td className="text-base w-1/4 px-4 py-2">
          {pdf.filename == null ? "no data" : pdf.filename}
        </td>
        <td className="w-1/4 px-4 py-2">
          <Link
            to={{
              pathname: `/view/${pdf.fileID}`,
              state: {
                fileID: pdf.fileID,
                oringinFileURL: pdf.oringinFileURL,
              },
            }}
          >
            <button
              onClick={() => {
                // this.deleteClick(pdfId, pdf.FirebaseStoreID);
                // console.log("this.deleteClick pdfId", pdfId);
                console.log("pdf.oringinFileURL", pdf.oringinFileURL);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              View
            </button>
          </Link>
        </td>
        <td className="w-1/4 px-4 py-2">
          <Link
            to={{
              pathname: `/edit/${pdf.fileID}`,
              state: {
                fileID: pdf.fileID,
                editVersionFileURL: pdf.editVersionFileURL,
              },
            }}
          >
            <button
              onClick={() => {
                // this.deleteClick(pdfId, pdf.FirebaseStoreID);
                // console.log("this.deleteClick pdfId", pdfId);
                console.log("pdf.editVersionFileURL", pdf.editVersionFileURL);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Edit
            </button>
          </Link>
        </td>
        <td className="w-1/4 px-4 py-2">
          <button
            onClick={() => {
              this.deleteClick(
                pdfId,
                pdf.fileID,
                pdf.editVersionStoreID,
                pdf.oringinStoreID
              );
              console.log("this.deleteClick pdfId", pdfId);
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete
          </button>
        </td>
      </tr>
      // </Router>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data,
  };
};

export default connect(mapStateToProps, actions)(ListItem);
