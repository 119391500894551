import React, { Component, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { PDFListRef } from "../config/firebase";
import * as actions from "../actions";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Document, Page, pdfjs } from "react-pdf";
import Img from "react-image";
import styled from "styled-components";

import ReactDOM from "react-dom";
import CanvasDraw from "react-canvas-draw";
import { ReactSketchCanvas } from "react-sketch-canvas";

// import throttle from "lodash.throttle";

import { AutoSizer } from "react-virtualized";

import ListItem from "./ListItem";
import "./style.css";

import useWindowDimensions from "../hooks/dimension";

const RenderCanvas = (img) => {
  console.log("img src", img.imgURL, typeof img);
  const ref = useRef();
  //hook
  const { height, width } = useWindowDimensions();

  const brushColor = "red",
    brushRadius = 2,
    lazyRadius = 0;

  let randomWidth = Math.random() * 1.5;
  console.log("randomWidth ", randomWidth);

  console.log("height:", height, "width: ", width, width - 20 * 2);
  {
    /* <ReactSketchCanvas
      background={img.imgURL}
      strokeWidth={2}
      strokeColor="red"
      height={width < 768 ? height * 0.8 : height}
      width={width < 768 ? width - 20 * 2 - 2 : (width - 20 * 2) * 0.6 - 2}
    />*/
  }
  return (
    <CanvasDraw
      ref={ref}
      brushColor={brushColor}
      brushRadius={brushRadius}
      lazyRadius={lazyRadius}
      canvasHeight={width < 768 ? height * 0.8 : height}
      canvasWidth={
        width < 768
          ? width - 20 * 2 - 2 + randomWidth
          : (width - 20 * 2) * 0.6 - 2 + randomWidth
      }
      imgSrc={img.imgURL}
    />
  );
};

class ViewFile extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  state = {
    viewData: "",
    fileID: "",
    fileURL: "",
    numPages: null,
    pageNumber: 1,
    PDFWidth: null,
  };

  myInput = React.createRef();

  componentDidMount() {
    const { fileID, editVersionFileURL } = this.props.location.state;
    console.log("fileID, editVersionFileURL", fileID, editVersionFileURL);
    this.setState({
      fileID: fileID,
      fileURL: editVersionFileURL,
      numPages: editVersionFileURL.length,
    });

    //https://github.com/wojtekmaj/react-pdf/issues/321
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    // this.setPDFWidth();

    // event listener when window is resized

    window.addEventListener("resize", this.throttledSetPDFWidth);
  }

  componentWillMount() {
    // this.props.fetchPDFList();
    // console.log(this.props.fetchPDFList());

    window.removeEventListener("resize", this.throttledSetPDFWidth);
  }

  handleBack() {
    this.props.history.push("/");
  }

  // setPDFWidth = () => {
  //   const width = this.myInput.current.offsetWidth;
  //   console.log("myInput.current.offsetWidth ", width);
  //   this.setState({ PDFWidth: width });
  // };

  changePage = (offset) =>
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + offset,
    }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  getDisplayData = (viewData) => {
    console.log("Data pass to the View Page", viewData);
  };

  render() {
    const { fileID, fileURL, pageNumber, numPages } = this.state;
    const { PDFWidth } = this.state;

    // const { data, viewData } = this.props;
    // console.log("fileID, oringinFileURL render", fileID, oringinFileURL);
    // console.log("data at viewpage", data);
    // console.log(this.myInput);
    return (
      <Container>
        <button
          onClick={() => this.props.history.goBack()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          &lt; Back
        </button>

        <EditPageContainer>
          <ImageCanvas>
            <div>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <div>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={this.previousPage}
                >
                  Previous
                </button>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={this.nextPage}
                >
                  Next
                </button>
              </div>
            </div>
            {/* <Img src={fileURL[pageNumber - 1]} /> */}
            <RenderCanvas imgURL={fileURL[pageNumber - 1]} />
          </ImageCanvas>

          <MarkingContolPanel>
            <p className="text-2xl">
              ViewFile Page <br /> Currently using React {React.version}
            </p>
            <p className="text-2xl">
              fileID: {this.state.fileID} <br /> <br />
              {/* fileURL[0]: {this.state.fileURL[0]} <br /> <br /> */}
              {/* fileURL: {this.state.fileURL} */}
            </p>
          </MarkingContolPanel>
        </EditPageContainer>

        {/* <div ref={this.myInput}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Document
                file={this.state.fileURL}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  //width={PDFWidth}
                  width={width}
                />
              </Document>
            )}
          </AutoSizer>
        </div> */}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 20px;
`;

const EditPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageCanvas = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  @media only screen and (min-width: 768px) {
    max-width: 60%;
    height: 100%;
  }
  border: 1px solid black;
`;

const MarkingContolPanel = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 20%;
  @media only screen and (min-width: 768px) {
    max-width: 40%;
    height: 100%;
  }
  border: 1px solid black;
  background-color: red;
`;

const mapStateToProps = ({ data }) => {
  return {
    data,
  };
};

export default connect(mapStateToProps, actions)(ViewFile);
